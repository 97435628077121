import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import SaveButton from '@/components/SaveButton.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import { companyConfiguracaoIntegracaoBancariaStore, contaBancariaStore, pixStore, } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let PixIntegradorConfiguracaoCreateComponent = class PixIntegradorConfiguracaoCreateComponent extends Vue {
    constructor() {
        super(...arguments);
        this.bancoSelected = [];
        this.entityObject = {};
        this.loading = false;
        this.contaBancaria = null;
        this.bancos = [
            { id: 41, codigo: '748', nome: 'Banco Cooperativo Sicredi S.A.' },
            { id: 181, codigo: '341', nome: 'Itaú Unibanco S.A.' },
            { id: 232, codigo: '364', nome: 'Efí By Gerencianet' },
        ];
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    get getContasBancaria() {
        return contaBancariaStore.contasBancarias;
    }
    async pixGerarCertificadoCsr() {
        await pixStore.pixGerarCertificadoCsr({
            banco_codigo: this.bancoSelected[1].codigo,
            company_id: this.propCompanyId,
        });
    }
    async submit() {
        // @ts-ignore
        this.entityObject.is_active = true;
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        // const isValidBanco =
        // @ts-ignore
        // await this.$refs.searchBancoComponent.$refs.observer.validate();
        if (!isValid && !isValidBanco) {
            return;
        }
        this.entityObject.banco_id = this.bancoSelected[1].id;
        this.entityObject.company_id = this.propCompanyId;
        this.entityObject.conta_bancaria_id = this.contaBancaria;
        this.entityObject.client_id = this.entityObject.client_id.toLowerCase();
        if (this.propId) {
            await companyConfiguracaoIntegracaoBancariaStore.updateCompanyConfiguracaoIntegracaoBancaria(this.entityObject);
        }
        else {
            await companyConfiguracaoIntegracaoBancariaStore.createCompanyConfiguracaoIntegracaoBancaria(this.entityObject);
            this.entityObject = {};
        }
        this.$emit('on-cancel');
    }
    async mounted() {
        this.loading = true;
        if (this.propId) {
            this.entityObject =
                await companyConfiguracaoIntegracaoBancariaStore.getCompanyConfiguracaoIntegracaoBancaria(this.propId);
            this.bancoSelected = this.entityObject.banco;
        }
        else {
            this.entityObject = {};
        }
        await contaBancariaStore.getContasBancarias();
        this.loading = false;
    }
};
__decorate([
    Prop({ default: null })
], PixIntegradorConfiguracaoCreateComponent.prototype, "propCompanyId", void 0);
__decorate([
    Prop({ default: null })
], PixIntegradorConfiguracaoCreateComponent.prototype, "propId", void 0);
PixIntegradorConfiguracaoCreateComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            SaveButton,
            EntidadeLinkComponent,
        },
    })
], PixIntegradorConfiguracaoCreateComponent);
export default PixIntegradorConfiguracaoCreateComponent;
