import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SyngooConfiguracao from '@/views/main/syngoo/Configuracao.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import PixIntegradorConfiguracaoComponent from '@/components/PixIntegradorConfiguracaoComponent.vue';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
import PlugnotasConfiguracao from '@/components/PlugnotasConfiguracao.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import SaveButton from '@/components/SaveButton.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import _ from 'lodash';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { alunoStore, bryStore, cargoStore, companyConfiguracaoStore, companyStore, departamentoStore, enderecoStore, financeiroParcelaMovimentacaoStore, financeiroParcelaStore, userStore, } from '@/store';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('email', {
    getMessage: (field) => `${field} deve ser um email correto!`,
    validate: async (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!re.test(email);
    },
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.loading = false;
        this.loadingFinanceiro = true;
        this.search = '';
        this.valid = false;
        this.nome_fantasia = '';
        this.razao_social = '';
        this.escola = null;
        this.cnpj = '';
        this.is_active = true;
        this.permite_inserir_aluno_sem_prospex = false;
        this.city_codigo_municipio_completo = null;
        this.is_school = true;
        this.email = '';
        this.unidade_telefone = '';
        this.unidade_whatsapp = '';
        this.proprietario_telefone = '';
        this.responsavel_financeiro_celular = '';
        this.responsavel_financeiro_nome = '';
        this.responsavel_financeiro_email = '';
        this.valor_parcela = 0;
        this.contrato_data_assinatura = null;
        this.time_zone = null;
        this.financeiro_data_primeira_parcela = null;
        this.inativacao_nao_inativar_antes_de = null;
        this.isencao_parcelas = 0;
        this.representante_legal = '';
        this.representante_legal_cpf = '';
        this.representante_legal_email = '';
        this.cidadeSelected = { codigo_municipio_completo: null };
        this.bairro = '';
        this.logradouro = '';
        this.numero = '';
        this.cep = '';
        this.editParcelaDialog = false;
        this.valorParcelaEdit = null;
        this.vencimentoParcelaEdit = null;
        this.idParcelaEdit = null;
        this.tab = 'tab_dados_cadastrais';
        this.financeiroParcelaSelected = null;
        this.indicacao_nome = null;
        this.indicacao_chave_pix = null;
        this.bry_contrato_chave = '';
        this.bry_contrato_status = '';
        this.indicacao_data_pagamento = null;
        this.status = null;
        this.statusFinanceiro = null;
        this.statusContratacao = null;
        this.statusTransicao = null;
        this.statusContato = null;
        this.bryColetaParticipantesData = {};
        this.aula_online_allow = null;
        this.liberar_portal_aluno = null;
        this.loadingExluirAlunoContratoFinanceiro = false;
        this.versionFree = false;
        this.usuarioResponsavelSelected = null;
        this.renderizarSearchEntidadeComponent = true;
        this.isentoFinanceiro = false;
        this.usuariosHeaders = [
            { text: 'Nome', value: 'full_name' },
            { text: 'E-mail', value: 'email' },
            { text: 'Cargo', value: 'cargo_id' },
            { text: 'Departamento', value: 'departamento_id' },
            { text: 'Ativo', value: 'is_active' },
        ];
        this.financeiroParcelaHeaders = [
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Categoria', value: 'financeiro_observacao' },
            { text: 'Valor Original', value: 'valor_original' },
            { text: 'Valor Pago', value: 'valor_pago' },
            { text: 'Ações', value: 'actions' },
        ];
        this.financeiroParcelasItems = [];
        this.financeiroParcelaDetails = { item: {}, value: {} };
        this.financeiroParcelaMovimentacaoItems = [];
        this.financeiroParcelaId = null;
        this.companyAllStatus = {};
        this.financeiroParcelaMovimentacaoHeaders = [
            { text: '', value: 'selected' },
            { text: 'Operação efetuada em', value: 'created_at' },
            { text: 'Data do pagamento', value: 'data_pagamento' },
            { text: 'Valor', value: 'valor_pago' },
            { text: 'Observação', value: 'observacao' },
            { text: 'Caixa', value: 'caixa' },
            { text: 'NF', value: 'plugnotas_id' },
            { text: '', value: 'actions' },
        ];
    }
    get usuarios() {
        return userStore.users;
    }
    get departamentoOptions() {
        return departamentoStore.departamentos;
    }
    get cargoOptions() {
        return cargoStore.cargos;
    }
    get companyStatus() {
        return this.companyAllStatus;
    }
    async carregaFinanceiro() {
        this.loadingFinanceiro = true;
        this.financeiroParcelasItems =
            await financeiroParcelaStore.getFinanceiroParcelas([
                `financeiro.tipo = 0 `,
                `financeiro_parcela.unidade_id = ${this.id}`,
            ]);
        this.loadingFinanceiro = false;
    }
    async onChangeCep(val) {
        if (val) {
            if (val.length === 9) {
                val = val.replace('-', '');
                const endereco = await enderecoStore.getEndereco(val);
                // @ts-ignore
                if (endereco.bairro) {
                    // @ts-ignore
                    this.bairro = endereco.bairro;
                }
                // @ts-ignore
                if (endereco.logradouro) {
                    // @ts-ignore
                    this.logradouro = endereco.logradouro;
                }
                this.cidadeSelected = {
                    // @ts-ignore
                    name: endereco.localidade + ' - ' + endereco.uf,
                    // @ts-ignore
                    nome_municipio: endereco.localidade,
                    // @ts-ignore
                    nome_uf: endereco.uf,
                    // @ts-ignore
                    codigo_municipio_completo: endereco.ibge,
                };
            }
        }
    }
    async getFinanceiroParcelaDetails({ item, value }) {
        this.financeiroParcelaDetails = { item, value };
        if (value) {
            this.financeiroParcelaMovimentacaoItems = [];
            this.financeiroParcelaId = item.id;
            await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(item.id);
            this.financeiroParcelaMovimentacaoItems =
                financeiroParcelaMovimentacaoStore.financeiroParcelaMovimentacoes;
            this.financeiroParcelaMovimentacaoItems = [
                ...this.financeiroParcelaMovimentacaoItems,
            ];
        }
    }
    // public async showPixDetails(item) {
    //   // @ts-ignore
    //   this.$refs.PixIntegradorExibirDetalhesPixDialog.show(
    //     item.company_configuracao_integracao_bancaria_id,
    //     item.txid,
    //     item.end_to_end_id
    //   );
    // }
    async isUpdate() {
        this.loading = true;
        // @ts-ignore
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.id = String(this.$router.currentRoute.params.id);
            // @ts-ignore
            const company = await companyStore.getCompany(this.id);
            this.nome_fantasia = company.nome_fantasia;
            this.razao_social = company.razao_social;
            this.cnpj = company.cnpj;
            this.is_active = company.is_active;
            this.permite_inserir_aluno_sem_prospex =
                company.permite_inserir_aluno_sem_prospex;
            this.city_codigo_municipio_completo =
                company.city_codigo_municipio_completo;
            this.is_school = company.is_school;
            this.email = company.email;
            this.unidade_telefone = company.unidade_telefone;
            this.unidade_whatsapp = company.unidade_whatsapp;
            this.proprietario_telefone = company.proprietario_telefone;
            this.responsavel_financeiro_celular =
                company.responsavel_financeiro_celular;
            this.responsavel_financeiro_nome = company.responsavel_financeiro_nome;
            this.responsavel_financeiro_email = company.responsavel_financeiro_email;
            this.escola = { id: company.escola_id };
            this.valor_parcela = company.valor_parcela;
            this.isentoFinanceiro = company.isento_financeiro;
            this.aula_online_allow = company.aula_online_allow;
            this.liberar_portal_aluno = company.liberar_portal_aluno;
            this.contrato_data_assinatura = company.contrato_data_assinatura;
            this.time_zone = company.time_zone;
            this.financeiro_data_primeira_parcela =
                company.financeiro_data_primeira_parcela;
            this.inativacao_nao_inativar_antes_de =
                company.inativacao_nao_inativar_antes_de;
            this.isencao_parcelas = company.isencao_parcelas;
            this.representante_legal = company.representante_legal;
            this.representante_legal_cpf = company.representante_legal_cpf;
            this.representante_legal_email = company.representante_legal_email;
            this.logradouro = company.logradouro;
            this.bairro = company.bairro;
            this.numero = company.numero;
            this.cep = company.cep;
            this.indicacao_nome = company.indicacao_nome;
            this.indicacao_chave_pix = company.indicacao_chave_pix;
            this.indicacao_data_pagamento = company.indicacao_data_pagamento;
            this.bry_contrato_chave = company.bry_contrato_chave;
            this.bry_contrato_status = company.bry_contrato_status;
            this.status = { id: company.status_id };
            this.statusContato = { id: company.status_contato_id };
            this.statusFinanceiro = { id: company.status_financeiro_id };
            this.versionFree = company.status_financeiro_id === 2;
            this.statusContratacao = { id: company.status_contratacao_id };
            this.statusTransicao = { id: company.status_transicao_id };
            this.cidadeSelected = {
                // @ts-ignore
                name: company.nome_municipio + ' - ' + company.nome_uf,
                nome_municipio: company.nome_municipio,
                nome_uf: company.nome_uf,
                codigo_municipio_completo: company.city_codigo_municipio_completo,
            };
            await userStore.getUsers(false);
            const userResponsavel = _.filter(userStore.users, (item) => {
                return item.id == company.responsavel_pela_unidade_drive_user_id;
            });
            if (userResponsavel.length > 0) {
                this.usuarioResponsavelSelected = {
                    tipo: 'Colaborador',
                    id: userResponsavel[0].id,
                    nome: userResponsavel[0].full_name,
                };
                this.renderizarSearchEntidadeComponent = false;
                await this.$nextTick();
                this.renderizarSearchEntidadeComponent = true;
            }
            await userStore.getUserByCompanyId({ company_id: this.id });
        }
        this.loading = false;
    }
    async cancelarEditParcela() {
        this.editParcelaDialog = false;
        this.valorParcelaEdit = null;
        this.vencimentoParcelaEdit = null;
        this.idParcelaEdit = null;
    }
    async confirmarEditParcela() {
        this.editParcelaDialog = false;
        let vencimento = this.vencimentoParcelaEdit;
        vencimento = vencimento.split('-');
        vencimento = vencimento[0] + '-' + vencimento[1] + '-' + vencimento[2];
        await financeiroParcelaStore.updateFinanceiroParcela({
            // @ts-ignore
            id: this.idParcelaEdit,
            vencimento,
            valor_integral: this.valorParcelaEdit,
        });
        this.financeiroParcelasItems =
            await financeiroParcelaStore.getFinanceiroParcelas([
                `financeiro.tipo = 0 `,
                `financeiro_parcela.unidade_id = ${this.id}`,
            ]);
    }
    async quitar(item) {
        this.$swal({
            title: 'Deseja realmente quitar o título?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const obj = {
                    financeiro_parcela_id: item.id,
                    valor_pago: item.valor_original,
                    entidade: {
                        id: item.financeiro_parcela_unidade_id,
                        tipo: 'Unidade',
                    },
                    caixa_id: null,
                    conta_bancaria: null,
                };
                await financeiroParcelaStore.quitarParcelaContaAPagar(obj);
                // @ts-ignore
                item.financeiro_parcela_status_id = this.getFinanceiroParcelaStatusIdPago();
            }
        });
    }
    async exportarDadosUnidade() {
        companyStore.exportCompanyData(this.id);
    }
    async inativarUnidade() {
        this.$swal({
            title: 'Confirma a inativação da unidade?',
            text: 'Essa função do sistema deve ser utilizada quando a unidade não irá mais usar o Drive. Caso prossiga a unidade e todos os usuários serão marcados como inativos.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                companyStore.unactiveCompany(this.id);
            }
        });
    }
    async exluirDadosUnidade() {
        this.$swal({
            title: 'Confirma a exclusão os dados da unidade?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                companyStore.deleteCompanyData(this.id);
            }
        });
    }
    async exluirAlunoContratoFinanceiro() {
        this.$swal({
            title: 'Confirma a exclusão de todos os alunos, contratos, financeiros?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loadingExluirAlunoContratoFinanceiro = true;
                await alunoStore.exluirAlunoContratoFinanceiro({ company_id: this.id });
                this.loadingExluirAlunoContratoFinanceiro = false;
            }
        });
    }
    async excluirUnidade() {
        this.$swal({
            title: 'Confirma a exclusão da unidade?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                companyStore.deleteCompany(this.id);
            }
        });
    }
    async gerarContrato() {
        const html = await companyStore.gerarContrato(this.id);
        const w = window.open();
        w.document.write(html);
        setTimeout(() => {
            w.print();
        }, 1000);
    }
    async gerarFinanceiro() {
        this.$swal({
            title: 'Confirma a geração de 12 parcelas de financeiro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await companyStore.gerarFinanceiro({
                    id: this.id,
                    financeiro_data_primeira_parcela: this.financeiro_data_primeira_parcela,
                    valor_parcela: this.valor_parcela,
                });
                this.financeiroParcelasItems =
                    await financeiroParcelaStore.getFinanceiroParcelas([
                        `financeiro.tipo = 0 `,
                        `financeiro_parcela.unidade_id = ${this.id}`,
                    ]);
                this.loading = false;
            }
        });
    }
    async editParcela(item) {
        this.valorParcelaEdit = item.valor_original;
        this.vencimentoParcelaEdit = item.vencimento;
        this.idParcelaEdit = item.id;
        this.editParcelaDialog = true;
    }
    async showPix(item) {
        // @ts-ignore
        const form = this.$refs.PixIntegradorPagamentoDialog;
        item.syngoo_mensagem_id = 17;
        form.show(item);
    }
    async doSubmit() {
        // @ts-ignore
        this.$refs.saveButton.changeLoadingStatus(true);
        // @ts-ignore
        const isValidCidade = await this.$refs.cidadeEmpresa.validateField();
        const isValid = await this.$refs.observer.validate();
        if (!isValid || !isValidCidade) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        let userResponsavelId = null;
        if (this.usuarioResponsavelSelected) {
            userResponsavelId = this.usuarioResponsavelSelected.id;
        }
        let inativacao_nao_inativar_antes_de = null;
        if (this.inativacao_nao_inativar_antes_de == '') {
            inativacao_nao_inativar_antes_de = null;
        }
        else {
            inativacao_nao_inativar_antes_de = this.inativacao_nao_inativar_antes_de;
        }
        if (this.id) {
            const company = {
                id: this.id,
                nome_fantasia: this.nome_fantasia,
                razao_social: this.razao_social,
                // @ts-ignore
                cnpj: this.cnpj
                    ? this.cnpj
                        .replaceAll('.', '')
                        .replaceAll('/', '')
                        .replaceAll('-', '')
                    : null,
                is_active: this.is_active,
                permite_inserir_aluno_sem_prospex: this.permite_inserir_aluno_sem_prospex,
                city_codigo_municipio_completo: this.cidadeSelected.codigo_municipio_completo,
                is_school: this.is_school,
                email: this.email,
                unidade_telefone: this.unidade_telefone
                    ? parseInt(this.unidade_telefone.replace(/[^0-9]/g, ''), 0)
                    : null,
                unidade_whatsapp: this.unidade_whatsapp
                    ? parseInt(this.unidade_whatsapp.replace(/[^0-9]/g, ''), 0)
                    : null,
                proprietario_telefone: this.proprietario_telefone
                    ? parseInt(this.proprietario_telefone.replace(/[^0-9]/g, ''), 0)
                    : null,
                responsavel_financeiro_celular: this.responsavel_financeiro_celular
                    ? parseInt(this.responsavel_financeiro_celular.replace(/[^0-9]/g, ''), 0)
                    : null,
                responsavel_financeiro_nome: this.responsavel_financeiro_nome,
                responsavel_financeiro_email: this.responsavel_financeiro_email,
                escola_id: this.escola.id,
                valor_parcela: this.valor_parcela,
                isento_financeiro: this.isentoFinanceiro,
                contrato_data_assinatura: this.contrato_data_assinatura,
                time_zone: this.time_zone,
                financeiro_data_primeira_parcela: this.financeiro_data_primeira_parcela,
                isencao_parcelas: this.isencao_parcelas,
                representante_legal: this.representante_legal,
                representante_legal_cpf: this.representante_legal_cpf,
                representante_legal_email: this.representante_legal_email,
                bairro: this.bairro,
                logradouro: this.logradouro,
                cep: this.cep,
                numero: this.numero,
                // @ts-ignore
                uf: this.cidadeSelected.nome_uf,
                // @ts-ignore
                cidade: this.cidadeSelected.nome_municipio,
                indicacao_nome: this.indicacao_nome,
                indicacao_chave_pix: this.indicacao_chave_pix,
                indicacao_data_pagamento: this.indicacao_data_pagamento,
                status_id: this.status.id,
                status_contratacao_id: this.statusContratacao.id,
                status_transicao_id: this.statusTransicao.id,
                status_contato_id: this.statusContato.id,
                aula_online_allow: this.aula_online_allow,
                liberar_portal_aluno: this.liberar_portal_aluno,
                responsavel_pela_unidade_drive_user_id: userResponsavelId,
                version_free: this.versionFree,
                inativacao_nao_inativar_antes_de,
            };
            // @ts-ignore
            const responseData = await companyStore.updateCompany(company);
            if (responseData) {
                await this.$router.push({ name: 'main-unidades' });
            }
        }
        else {
            const company = {
                nome_fantasia: this.nome_fantasia,
                razao_social: this.razao_social,
                // @ts-ignore
                cnpj: this.cnpj
                    ? this.cnpj
                        .replaceAll('.', '')
                        .replaceAll('/', '')
                        .replaceAll('-', '')
                    : null,
                is_active: this.is_active,
                permite_inserir_aluno_sem_prospex: this.permite_inserir_aluno_sem_prospex,
                city_codigo_municipio_completo: this.cidadeSelected.codigo_municipio_completo,
                is_school: this.is_school,
                email: this.email,
                unidade_telefone: this.unidade_telefone
                    ? parseInt(this.unidade_telefone.replace(/[^0-9]/g, ''), 0)
                    : null,
                unidade_whatsapp: this.unidade_whatsapp
                    ? parseInt(this.unidade_whatsapp.replace(/[^0-9]/g, ''), 0)
                    : null,
                proprietario_telefone: this.proprietario_telefone
                    ? parseInt(this.proprietario_telefone.replace(/[^0-9]/g, ''), 0)
                    : null,
                responsavel_financeiro_celular: this.responsavel_financeiro_celular
                    ? parseInt(this.responsavel_financeiro_celular.replace(/[^0-9]/g, ''), 0)
                    : null,
                responsavel_financeiro_nome: this.responsavel_financeiro_nome,
                responsavel_financeiro_email: this.responsavel_financeiro_email,
                escola_id: this.escola.id,
                valor_parcela: this.valor_parcela,
                isento_financeiro: this.isentoFinanceiro,
                time_zone: this.time_zone,
                isencao_parcelas: this.isencao_parcelas,
                representante_legal: this.representante_legal,
                representante_legal_cpf: this.representante_legal_cpf,
                representante_legal_email: this.representante_legal_email,
                bairro: this.bairro,
                logradouro: this.logradouro,
                cep: this.cep,
                numero: this.numero,
                // @ts-ignore
                uf: this.cidadeSelected.nome_uf,
                // @ts-ignore
                cidade: this.cidadeSelected.nome_municipio,
                aula_online_allow: this.aula_online_allow,
                liberar_portal_aluno: this.liberar_portal_aluno,
                version_free: this.versionFree,
                responsavel_pela_unidade_drive_user_id: userResponsavelId,
            };
            this.id = await companyStore.createCompany(company);
            await userStore.getUsers(false);
            if (this.id) {
                // @ts-ignore
                await this.$router.push({ name: 'main-unidades' });
            }
        }
        // @ts-ignore
        this.$refs.saveButton.changeLoadingStatus(false);
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    async mounted() {
        await this.getAllCompanyStatus();
        await this.isUpdate();
        await cargoStore.getCargos();
    }
    async getAllCompanyStatus() {
        this.companyAllStatus = await companyStore.companyGetAllStatus();
    }
    async sincronizar_sponte(tipoImportacao) {
        await companyConfiguracaoStore.sincronizarSponte({
            tipo_importacao: tipoImportacao,
            company_id: this.id,
        });
    }
    async sincronizar_matricom() {
        await companyConfiguracaoStore.sincronizarMatricom();
    }
    async bryColeta() {
        // this.bryColetaData = await bryStore.bryColeta(this.bry_contrato_chave); talvez seja necessario
        this.bryColetaParticipantesData = await bryStore.bryColetaParticipantes(this.bry_contrato_chave);
    }
};
__decorate([
    Watch('cep')
], Create.prototype, "onChangeCep", null);
Create = __decorate([
    Component({
        components: {
            DateFieldComponent,
            SyngooConfiguracao,
            OcorrenciaComponent,
            PixIntegradorConfiguracaoComponent,
            PixIntegradorPagamentoDialog,
            PlugnotasConfiguracao,
            PlugnotasConsultaEmiteButton,
            SaveButton,
            SearchCityComponent,
            TextExpandableComponent,
            LogShowComponent,
            ValidationObserver,
            ValidationProvider,
            SearchEntidadeComponent,
        },
    })
], Create);
export default Create;
