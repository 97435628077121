var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Nova integração")])]),(!_vm.loading)?_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"mb-2"},_vm._l((_vm.bancos),function(banco){return _c('v-col',{key:banco.id},[_c('clickable-card',{attrs:{"prop-object-to-return":banco,"prop-single-selection":true,"prop-card-title":banco.nome,"prop-loading":false},model:{value:(_vm.bancoSelected),callback:function ($$v) {_vm.bancoSelected=$$v},expression:"bancoSelected"}})],1)}),1),(_vm.bancoSelected.length > 0)?_c('div',[_c('v-row',[_c('v-col',[_c('ol',[(['748'].includes(_vm.bancoSelected[1].codigo))?_c('li',[_vm._v(" Efetuar download do certificado CSR "),_c('v-btn',{staticClass:"primary",attrs:{"x-small":""},on:{"click":_vm.pixGerarCertificadoCsr}},[_vm._v("download")])],1):_vm._e(),(['748'].includes(_vm.bancoSelected[1].codigo))?_c('li',[_vm._v("O franqueado deve realizar o upload do certificado CSR no Internet Banking e aguardar até três dias uteis")]):_vm._e(),(['748', '364'].includes(_vm.bancoSelected[1].codigo))?_c('li',[_vm._v(" O franqueado deve realizar o download do certificado autenticado pelo banco no internet banking e efetuar o upload aqui (Arquivo .pem) "),_c('v-btn',{staticClass:"primary",attrs:{"x-small":""}},[_vm._v("Upload")])],1):_vm._e(),_c('li',[_vm._v(" Informar alguma chave pix, é exigencia do banco central ter ao menos uma criada "),_c('validation-provider',{attrs:{"debounce":100,"name":"Chave PIX","vid":"chave_pix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.propId,"label":"Chave PIX","error-messages":errors},model:{value:(_vm.entityObject.chave_pix),callback:function ($$v) {_vm.$set(_vm.entityObject, "chave_pix", $$v)},expression:"entityObject.chave_pix"}})]}}],null,true)})],1),_c('li',[_vm._v(" Informar o client_id "),_c('br'),_c('validation-provider',{attrs:{"debounce":100,"name":"Client ID","vid":"client_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.propId,"label":"Client ID","error-messages":errors},model:{value:(_vm.entityObject.client_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "client_id", $$v)},expression:"entityObject.client_id"}})]}}],null,true)})],1),(['748'].includes(_vm.bancoSelected[1].codigo))?_c('li',[_vm._v(" Informar o client secret "),_c('br'),_c('validation-provider',{attrs:{"debounce":100,"name":"Client Secret","vid":"client_secret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.propId,"label":"Client Secret","type":"text","error-messages":errors},model:{value:(_vm.entityObject.client_secret),callback:function ($$v) {_vm.$set(_vm.entityObject, "client_secret", $$v)},expression:"entityObject.client_secret"}})]}}],null,true)})],1):_vm._e(),(['341'].includes(_vm.bancoSelected[1].codigo))?_c('li',[_vm._v(" Informar o token "),_c('br'),_c('validation-provider',{attrs:{"debounce":100,"name":"Token","vid":"token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.propId,"label":"Token","error-messages":errors},model:{value:(_vm.entityObject.token),callback:function ($$v) {_vm.$set(_vm.entityObject, "token", $$v)},expression:"entityObject.token"}})]}}],null,true)})],1):_vm._e(),_c('li',[_vm._v(" Opcional, atrelhar as operações com uma conta bancária "),_c('v-autocomplete',{attrs:{"items":_vm.getContasBancaria,"label":"Conta Bancária","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('small',[_vm._v(_vm._s(data.item.banco.nome)+" - "+_vm._s(data.item.conta))])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('v-list-item-title',[_vm._v("Banco")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.banco.nome))])],1),_c('v-col',[_c('v-list-item-title',[_vm._v("Agência")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.conta_bancaria ? data.item.conta_bancaria : '')+" ")])],1),_c('v-col',[_c('v-list-item-title',[_vm._v("Conta")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.conta)+" ")])],1)],1)],1)]}}],null,true),model:{value:(_vm.contaBancaria),callback:function ($$v) {_vm.contaBancaria=$$v},expression:"contaBancaria"}})],1)])])],1)],1):_vm._e()]}}],null,false,684505515)})],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.onCancel}},[_vm._v("cancelar")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("salvar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }